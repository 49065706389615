<template>
    <div class="success">
        <i class="el-icon el-icon-success" />
        <p>充值成功</p>
        <p class="desc">您可以在您的充值记录中查看</p>
    </div>
</template>
<style lang="stylus" scoped>
p
    margin 16px 0 0
.success
    flex-center()
    flex-direction column
    height 100%
    font-size 18px
    margin-top -80px
.desc
    font-size 16px
    margin-top 8px
    color color-grey
.el-icon
    color color-green
    font-size 96px
</style>
